<template>
  <base-detail-view
    :loading="loading"
    :title="
      this.type === 'create'
        ? 'Создание сводного отчета по ВКК'
        : 'Сводный отчет по ВКК'
    "
    :subTitle="SummaryReportStatus[dataSource.Status]"
    :isUnsaved="unsaved"
    @onSave="save"
    @onRefresh="refresh"
    fixedHeader
  >
    <report-activator
      slot="action"
      :report="['ActionPlanReport', 'SummaryReport', 'FullSummaryReport']"
      :params="{
        objectId: { name: 'pSummaryReport', value: $route.params.objectId },
      }"
    />
    <v-btn
      v-if="
        dataSource.DoneSteps &&
        dataSource.Status === 0 &&
        dataSource.DoneSteps.length >= 6 &&
        dataSource.IsAuthor
      "
      @click="updateStatus(1)"
      dark
      color="blue"
      class="ml-4"
      slot="action"
    >
      Передать на подпись
    </v-btn>

    <v-btn
      v-if="dataSource.Status === 1 && dataSource.IsApprovement"
      @click="sign()"
      dark
      color="blue"
      class="ml-4"
      slot="action"
    >
      Подписать и опубликовать
    </v-btn>

    <v-btn
      v-if="
        dataSource.Status === 1 &&
        (dataSource.IsAuthor || dataSource.IsApprovement)
      "
      @click="updateStatus(0)"
      dark
      color="blue"
      class="ml-4"
      slot="action"
    >
      Вернуть на доработку
    </v-btn>

    <v-btn
      v-if="false && dataSource.Status === 2 && dataSource.IsApprovement"
      @click="updateStatus(0)"
      dark
      color="blue"
      class="ml-4"
      slot="action"
    >
      Вернуть на доработку
    </v-btn>

    <div class="d-flex summary-report-edit">
      <div class="summary-report-edit__nav" v-if="dataSource.Step">
        <v-stepper v-model="step" vertical style="position: sticky; top: 98px">
          <v-stepper-step
            :complete="
              dataSource &&
              dataSource.DoneSteps &&
              dataSource.DoneSteps.includes(1)
            "
            step="1"
            editable
            :edit-icon="'$complete'"
          >
            Начало
          </v-stepper-step>
          <v-stepper-content step="1">
            <!--
            <v-card color="grey lighten-1" class="mb-12" height="200px"></v-card
            >-->
          </v-stepper-content>

          <v-stepper-step
            :complete="
              dataSource &&
              dataSource.DoneSteps &&
              dataSource.DoneSteps.includes(2)
            "
            step="2"
            :editable="dataSource.Id > 0"
            :edit-icon="'$complete'"
          >
            Проверки
          </v-stepper-step>

          <v-stepper-content step="2" />

          <v-stepper-step
            :complete="
              dataSource &&
              dataSource.DoneSteps &&
              dataSource.DoneSteps.includes(3)
            "
            step="3"
            :editable="dataSource.Id > 0"
            :edit-icon="'$complete'"
          >
            Нежелательные события
          </v-stepper-step>
          <v-stepper-content step="3" />
          <v-stepper-step
            :complete="
              dataSource &&
              dataSource.DoneSteps &&
              dataSource.DoneSteps.includes(4)
            "
            step="4"
            :editable="dataSource.Id > 0"
            :edit-icon="'$complete'"
          >
            Статистические показатели
          </v-stepper-step>
          <v-stepper-content step="4" />
          <v-stepper-step
            :complete="
              dataSource &&
              dataSource.DoneSteps &&
              dataSource.DoneSteps.includes(5)
            "
            step="5"
            :editable="dataSource.Id > 0"
            :edit-icon="'$complete'"
          >
            Мониторинги
          </v-stepper-step>
          <v-stepper-content step="5" />
          <v-stepper-step
            :complete="
              dataSource &&
              dataSource.DoneSteps &&
              dataSource.DoneSteps.includes(6)
            "
            step="6"
            :editable="dataSource.Id > 0"
            :edit-icon="'$complete'"
          >
            Выводы
          </v-stepper-step>
          <v-stepper-content step="6" />
          <v-stepper-step
            :complete="
              dataSource &&
              dataSource.DoneSteps &&
              dataSource.DoneSteps.includes(7)
            "
            step="7"
            :editable="dataSource.Id > 0"
            :edit-icon="'$complete'"
          >
            Сводная таблица
          </v-stepper-step>
          <v-stepper-content step="7" />
        </v-stepper>
      </div>
      <div class="summary-report-edit__content">
        <consolidated-report-steps
          v-if="dataSource.Step"
          :step.sync="step"
          ref="steps"
          :loading.sync="loading"
          :isUnsaved.sync="unsaved"
          :data="dataSource"
          :readonly="readonly"
        />
      </div>
    </div>
  </base-detail-view>
</template>

<script>
import ReportActivator from "../../components/report/ReportActivator.vue";
import { SummaryReportStatus } from "@/data/enums";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import { mapActions } from "vuex";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import SummaryReportService from "@/services/SummaryReportService";

export default {
  name: "view-ConsolidatedReportEdit",
  components: {
    BaseDetailView,
    consolidatedReportSteps: () => import("./components/steps/index.vue"),
    ReportActivator,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  provide() {
    return {
      back: this.back,
      forward: this.forward,
      getStep: () => this.step,
      getDoneSteps: () => this.dataSource?.DoneSteps || [],
      setDefaultData: this.setDefaultData,
    };
  },
  data: function () {
    return {
      step: 1,
      unsaved: false,
      // Api
      apiService: SummaryReportService,
      getDefaultObject: () => ({
        Id: -Math.round(Math.random() * 100000),
        Status: 0,
        DoneSteps: [],
        Step: 1,
      }),
      getObjectText: (obj, isNew) => (isNew ? "новое" : "22.06.2024"),
      loading: true,
      dataSource: {},
      valid: false,
      showCompleted: false,
    };
  },
  computed: {
    SummaryReportStatus() {
      return SummaryReportStatus;
    },
    readonly() {
      return this?.dataSource?.Status !== 0;
    },
  },
  watch: {
    step(val, old) {
      if (this.dataSource.Id <= 0 && val !== 1) {
        this.step = 1;
        this.$alert("Сохраните отчет");
      } else if (this.unsaved) {
        this.save(old);
      }
    },
  },
  async mounted() {
    await this.init();
    if (this.type === "create") {
      this.unsaved = true;
    }
  },

  methods: {
    async sign() {
      if (
        await this.$confirm(
          `После подписания и опубликования отчета дальнейшее его изменение не возможно!`,
          {
            buttonTrueText: "Подписать и опубликовать",
            buttonTrueColor: "primary",
          }
        )
      )
        this.updateStatus(2);
    },
    ...mapActions([]),
    setDefaultData(e) {
      this.dataSource.IsApprovement = e.IsApprovement;
      this.dataSource.IsAuthor = e.IsAuthor;
      this.dataSource.DoneSteps = e.DoneSteps;
      this.dataSource.IsAuthor = e.IsAuthor;

      this.originObject.IsApprovement = e.IsApprovement;
      this.originObject.IsAuthor = e.IsAuthor;
      this.originObject.DoneSteps = e.DoneSteps;
      this.originObject.IsAuthor = e.IsAuthor;
    },
    back() {
      if (this.step > 1) this.step--;
    },
    forward() {
      if (this.step < 7) this.step++;
    },
    DataFromServerNormalize(data) {
      return data;
    },

    async updateStatus(status) {
      try {
        await SummaryReportService.updateStatus(this.dataSource.Id, status);
        this.dataSource.Status = status;
      } catch (error) {
        console.log(error);
      }
    },

    async save(step = -1) {
      let _step = this.step;
      if (step !== -1) {
        _step = step;
      }
      const res = (await this.$refs.steps.$refs["step" + _step].save()).data;
      this.setDefaultData(res);
    },
    async refresh() {
      await this.$refs.steps.$refs["step" + this.step].init();
    },
  },
};
</script>

<style lang="scss">
.summary-report-edit {
  &__nav {
    width: 273px;

    margin-right: 20px;
  }
  &__content {
    flex: 1;
  }
}
</style>
